(() => {
  const adjustCarouselHeight = () => {
    const carouseInstances = Object.values(window._tnsInstances || {});
    carouseInstances.forEach(inst => {
      // @ts-ignore
      inst?.updateSliderHeight?.();

      // @ts-ignore
      inst?.events?.on?.('transitionEnd', info => {
        // @ts-ignore
        inst?.updateSliderHeight?.();
      });
    });
  };

  const init = () => {
    adjustCarouselHeight();
  };

  if (document.readyState === 'loading') {
    window.addEventListener('load', init);
  } else {
    init();
  }
})();
